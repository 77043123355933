import { useQuery } from "react-query";
import { useAxios } from "../../../hooks/axios";

const useGetCity = () => {
  const axios = useAxios();
  return useQuery("usercity", async () => {
    const { data } = await axios.get("/areas/v1/city?latitude=0&longitude=0");
    return data?.data;
  });
};
export default useGetCity;
