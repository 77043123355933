import React, { createContext, useState, useContext, useEffect } from "react";
import Cookies from "js-cookie";
import { useAxios } from "../hooks/axios";
import { useRouter } from "next/router";
import { toast } from "react-toastify";
import useCurrentUser from "../components/Authentication/hooks/useCurrentUser";
import { useTranslation } from "next-i18next";
import { useQueryClient } from "react-query";
import { publicUrls, toastOptions } from "../utils/utils";
import jwtDecode from "jwt-decode";
import useGetCity from "../components/Authentication/hooks/useGetCity";
import { event } from "../utils/gtm";
//api here is an axios instance which has the baseURL set according to the env.
// import api from "../hooks/axios";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chatToken, setChatToken] = useState(false);
  const [pushToken, setPushToken] = useState(false);
  const [notificationCount, setNotificationCount] = useState(null);
  const [ownerType, setOwnerType] = useState(null);
  const [formCompleted, setFormCompleted] = useState(false);
  const [signUpFormData, setSignUpFormData] = useState(null);
  const [loginCredential, setLoginCredential] = useState(null);
  const [timer, setTimer] = useState();
  const [profileComplete, setPofileComplete] = useState();
  const [randomNumber, setRandomNumber] = useState(null);
  const [mapDrawer, setMapDrawer] = useState(true);
  const [nafathTimer, setNafathTimer] = useState(null);
  const [profileNafath, setProfileNafath] = useState(null);
  const [userLoaded, setUserLoaded] = useState(false);
  const [loginRefresh, setLoginRefresh] = useState(false);
  const queryClient = useQueryClient();
  const [city, setCity] = useState(null);
  const [accessToken, setAccessToken] = useState('');
  const [idToken, setIdToken] = useState('');
  const [scope, setScope] = useState('');
  const [isProfileComplete, setIsProfileComplete] = useState('');
  const [providedId, setProvidedId] = useState('');
  const [sellerId, setSellerId] = useState(null);
  const api = useAxios();

  const {
    refetch: refetchUser,
    data: userInfo,
    status: userLoadingStatus,
    error,
  } = useCurrentUser(false);

  const { data: cities } = useGetCity();
  useEffect(() => {
    if (cities) {
      setCity(cities);
      Cookies.set("city_id", cities?.id);
      Cookies.set("latitude", cities?.latitude);
      Cookies.set("longitude", cities?.longitude);
      Cookies.set("city_name_en", cities?.nameEn);
      Cookies.set("city_name_ar", cities?.nameAr);
    }
  }, [cities]);
  // console.log('user', user);
  // console.log('userInfo', userInfo);

  useEffect(() => {
    if (userInfo) {
      setUser(userInfo);
      if (publicUrls[router?.pathname]?.length < 1 || router?.query?.redirect) {
        if (router?.query?.redirect) {
          router.replace({
            pathname: router?.query?.redirect,
          });
        } else {
          router.replace({
            pathname: userInfo?.isProfileComplete ? "/" : "profile", //profile
          });
        }
      } else {
        //publicUrls[router?.pathname];
        if (!publicUrls.includes(router?.pathname)) {
          // console.log('router?.pathnam' ,router?.pathname);
          router.replace({
            pathname:
              userInfo?.isProfileComplete && router?.pathname != "/verify/otp"
                ? router?.pathname
                : "/", //instead "/" : "profile"
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (loginRefresh) {
      if (pushToken) {
        api.post("/messages/v1/push", {
          ProviderToken: pushToken,
          NativeToken: "",
          ExpiredToken: "",
        });
      }
      try {
        getChatToken();
        refetchUser();
      } catch (error) { }
    }
  }, [loginRefresh]);

  const getChatToken = async () => {
    // const token = Cookies.get("access_token");

    await api
      .get("chat/v1/user/token")
      .then((response) => {
        setChatToken(response?.data?.data?.chatToken);
        Cookies.set("chatToken", response?.data?.data?.chatToken);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get("access_token");
      let is_Profile_Complete = Cookies.get("IsProfileComplete");
      let decoded = null;
      if (is_Profile_Complete == "False" && token) {
        decoded = jwtDecode(token);
        is_Profile_Complete = decoded?.isProfileComplete == "True";
      }

      if (!token && !publicUrls.includes(router?.pathname)) {
        router.push({
          pathname: "/login",
          query: { redirect: router?.pathname },
        });
      }

      if (token) {
        const chatToken = Cookies.get("chatToken");
        if (chatToken) {
          setChatToken(chatToken);
        } else {
          await getChatToken();
        }
        if (is_Profile_Complete) {
          refetchUser();
        }
      }

      setLoading(false);
    }

    loadUserFromCookies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoaded]);

  const login = async (values) => {
    setLoading(true);
    try {
      const { data } = await api.post("identity/connect/token", values, {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      });
      if (data) {

        if (typeof window !== 'undefined') {
          window.localStorage.setItem('access_token', data?.access_token);
          window.localStorage.setItem('id_token', data?.id_token);
          window.localStorage.setItem('scope', data?.scope);
          window.localStorage.setItem('IsProfileComplete', data?.IsProfileComplete);
          window.localStorage.setItem('provided_id', values.providerId);
          window.localStorage.setItem('expires_in', data?.expires_in);
        }
        // Cookies.set("access_token", data?.access_token, {
        //   expires: data?.expires_in,
        // });
        // Cookies.set("id_token", data?.id_token, {
        //   expires: data?.expires_in,
        // });
        // Cookies.set("scope", data?.scope, { expires: data?.expires_in });
        // Cookies.set("IsProfileComplete", data?.IsProfileComplete, {
        //   expires: data?.expires_in,
        // });
        // Cookies.set("provided_id", values?.providerId);
        const expiresInInSeconds = data?.expires_in;
        setTimeout(() => {
          console.log('data.expires_in', expiresInInSeconds);
          logout(); // Call your logout function here
        }, expiresInInSeconds * 1000);

        event({
          action: "otp_success",
          params: {}
        });

        // if (pushToken) {
        //   api.post("/messages/v1/push", {
        //     ProviderToken: pushToken,
        //     NativeToken: "",
        //     ExpiredToken: "",
        //   });
        // }
        // try {
        //   await api.get("chat/v1/user/token").then((response) => {
        //     setChatToken(response?.data?.data?.chatToken);
        //     Cookies.set("chatToken", response?.data?.data?.chatToken);
        //   });
        // } catch (error) {}

        //queryClient.invalidateQueries(["buyer", "profile"]);
        toast.success(t("otp_verified"), toastOptions);
        if (data?.refresh_token === "" || data?.refresh_token === undefined || data?.refresh_token === null) {
          if (router?.locale === 'ar') {
            router.push(`/login`);
          } else if (router?.locale === 'en') {
            router.push(`/en/login`);
            // window.location.href = "en/login";
          }
        } else {

          if (data?.IsProfileComplete) {
            Cookies.set("access_token", data?.access_token, {
              expires: data?.expires_in,
            });
            Cookies.set("id_token", data?.id_token, {
              expires: data?.expires_in,
            });
            Cookies.set("scope", data?.scope, { expires: data?.expires_in });
            Cookies.set("IsProfileComplete", data?.IsProfileComplete, {
              expires: data?.expires_in,
            });
            Cookies.set("provided_id", values?.providerId);
            setUserLoaded(true);
            window.location.href = `/${router.locale}`;
            // router?.push({
            //   pathname: "/",
            // });
          } else {
            window.location.href = `/${router.locale}/sign-up/account-type`;
            // router?.push({
            //   pathname: "/sign-up/account-type",
            // });
          }
        }
        setLoading(false);
      
      }
    } catch (err) {
      setLoading(false);
      return new Error("invalid");
    }
  };

  const socialLogin = async (token) => {
    setLoading(true);
    let decoded = null;
    try {
      if (token) {
        Cookies.set("access_token", token, {
          expires: 86400 * 2, //2days
        });

        let accToken = Cookies.get("access_token");
        // let accToken =   window.localStorage.setItem("@access_token", true);

        decoded = jwtDecode(accToken);
        if (decoded?.isProfileComplete == "True") {
          // window.localStorage.setItem("@IsProfileComplete", true);

          Cookies.set("IsProfileComplete", "true");
          setUserLoaded(true);
          toast.success(t("login_successful"), toastOptions);
        } else {
          router?.push({
            pathname: "/sign-up/account-type",
          });
          return;
        }
        //refetchUser();
        router.replace({
          pathname: "/",
        });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      return new Error("invalid");
    }
  };

  const logout = async () => {
    setLoading(true);
    const { data } = await api.post("identity/v1/logins/signout");
    Cookies.remove("access_token");
    Cookies.remove("id_token");
    Cookies.remove("scope");
    Cookies.remove("IsProfileComplete");
    Cookies.remove("chatToken");
    Cookies.remove("sanadak_fcm_token");
    Cookies.remove("provided_id");
    Cookies.remove("recent_search");
    localStorage.clear();
    sessionStorage.clear();
    // queryClient.invalidateQueries(["user"]);
    setUser(null);
    setChatToken(null);
    toast.success(t("logged_out_succesfully"), toastOptions);
    setLoading(false);
    if (router?.locale === 'ar') {
      // router.push(`/login`);
      window.location.href = "/login";
    } else if (router?.locale === 'en') {
      window.location.href = "/en/login";
      // router.push(`/en/login`);

    }
    refetchUser();
    // router.push({
    //   pathname: "/login",
    // });
  };


  const logoutFirstLogin = async () => {
    setLoading(true);
    const { data } = await api.post("identity/v1/logins/signout");
    Cookies.remove("access_token");
    Cookies.remove("id_token");
    Cookies.remove("scope");
    Cookies.remove("IsProfileComplete");
    Cookies.remove("chatToken");
    Cookies.remove("sanadak_fcm_token");
    Cookies.remove("provided_id");
    Cookies.remove("recent_search");
    // queryClient.invalidateQueries(["user"]);
    setUser(null);
    setChatToken(null);
    setLoading(false);
    refetchUser();
    sessionStorage.clear();
    // router.push({
    //   pathname: "/login",
    // });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        userLoaded,
        login,
        loading,
        logout,
        logoutFirstLogin,
        refetchUser,
        setUser,
        socialLogin,
        setPushToken,
        setNotificationCount,
        notificationCount,
        pushToken,
        chatToken,
        ownerType,
        setOwnerType,
        signUpFormData,
        setSignUpFormData,
        formCompleted,
        setFormCompleted,
        loginCredential,
        setLoginCredential,
        setLoginRefresh,
        timer,
        setTimer,
        profileComplete,
        setPofileComplete,
        setChatToken,
        city,
        randomNumber,
        setRandomNumber,
        mapDrawer,
        setMapDrawer,
        nafathTimer,
        setNafathTimer,
        profileNafath,
        setProfileNafath,
        setProvidedId,
        sellerId,
        setSellerId
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const ProtectRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();
  if (
    isLoading ||
    (!isAuthenticated && window.location.pathname !== "/login")
  ) {
    return <p>Loading...</p>;
  }
  return children;
};
