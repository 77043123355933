import { useQuery } from "react-query";
import { useAxios } from "../../../hooks/axios";
import { useRouter } from "next/router";

const useCurrentUser = (enabled = false) => {
  const axios = useAxios();
  const router = useRouter()
  return useQuery(
    "user",
    async () => {
      try {
        const { data } = await axios.get("/identity/v1/profile");
        // console.log('data', data);
        // if (typeof window !== 'undefined') {
        //   window.localStorage.setItem('licenseNumber', data?.data?.licenseNumber);
        //   window.localStorage.setItem('userTypeText', data?.data?.userTypeText);
        // }
        return data?.data;
      } catch (error) {
        // Handle the error, you can log it or throw a new error
        // console.error("Error fetching user data:", error);

        // throw new Error("Failed to fetch user data");
      }
    },
    {
      enabled: enabled,
    },
    {
      onError: (error) => {
        toast.error(error?.response?.data?.error?.message, toastOptions);
      },
    }
  );
};
export default useCurrentUser;
