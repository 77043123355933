export const pageview = url => {
    window.gtag("config", "G-D14FXVG117", {
        page_path: url,
    })
}

export const event = ({ action, params }) => {
    if(process.env.NODE_ENV != "development") {
        window.gtag("event", action, params)
    }
}