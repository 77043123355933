import { useRouter } from "next/router";
import { useQuery } from "react-query";
import { useAxios } from "../../../hooks/axios";

const useGetProperties = (filter) => {
  const router = useRouter();

  // const { ...params } = filter ?? {};
  const filteredOutValues = Object.fromEntries(
    Object.entries(filter).filter(([_, value]) => value !== "" && value != null)
  );
  const axios = useAxios();
  return useQuery(
    ["properties", { filteredOutValues }],
    async () => {
      const data = await axios.post(
        `/search/v1/listings/advance`,
        {
          northWestCoordinate: {
            Latitude: filter?.mapBounds?.nw?.lat || 0,
            Longitude: filter?.mapBounds?.nw?.lng || 0,
          },
          southEastCoordinate: {
            Latitude: filter?.mapBounds?.se?.lat || 0,
            Longitude: filter?.mapBounds?.se?.lng || 0,
          },
          page: filter?.page,
          pageSize: filter?.perPage,
          // listingType: filter?.listingType || null,
          districtIds: filter?.districts || null,
          sellerIds: filter?.sellerIds || null,
          listingType: filter?.listingType || null,
          propertyType: filter?.propertyType || null,
          cityId: filter?.cityId || null,
          numberBedrooms: filter?.numberBedrooms || null,
          numberBathrooms: filter?.numberBathrooms || null,
          furnishType: filter?.furnishType || null,
          isOccupiedBefore: filter?.isOccupiedBefore || null,
          minPrice: filter?.minPrice || null,
          maxPrice: filter?.maxPrice || null,
          minArea: filter?.minArea || null,
          maxArea: filter?.maxArea || null,
        },
        {
          headers: {
            "Accept-Language": router?.locale == "en" ? "en-US" : "ar-SA en-US",
          },
        }
      );
      return data?.data?.data;
    },

    { keepPreviousData: true, enabled: filter?.enabled ?? true }
  );
};

export default useGetProperties;
