import React, { useEffect, useState } from "react";
import { appWithTranslation, useTranslation } from "next-i18next";
// import { DefaultSeo } from "next-seo";
import "../styles/globals.css";
import SEO from "../next-seo.config";
import { ThemeProvider } from "@mui/material";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import theme, { themeAr } from "../src/utils/theme";
import { useRouter } from "next/router";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import CssBaseline from "@mui/material/CssBaseline";
import NextNProgress from "nextjs-progressbar";
import { PropertyContextProvider } from "../src/components/PropertyWithFilter/context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "../src/context/auth";
import Head from "next/head";
import { GMAP_KEY } from "../src/utils/utils";
import { GoogleAnalytics } from "nextjs-google-analytics";
import { pageview } from "../src/utils/gtm";
import { DefaultSeo } from "next-seo";
import SnapchatPixel from "../src/components/SnapchatPixel";
const isBrowser = typeof document !== "undefined";
let insertionPoint;

if (isBrowser) {
  const emotionInsertionPoint = document.querySelector(
    'meta[name="emotion-insertion-point"]'
  );
  insertionPoint = emotionInsertionPoint ?? undefined;
}

const cacheRtl = createCache({
  key: "mui-style-rtl",
  stylisPlugins: [prefixer, rtlPlugin],
  insertionPoint,
});

const cacheLtr = createCache({
  key: "mui-style-ltr",
  insertionPoint,
});


function MyApp({ Component, pageProps }) {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { locale } = router;
  const { i18n } = useTranslation();
  const [pageLoading, setPageLoading] = useState(true);
  useEffect(() => {
    const handleRouteChange = url => {
      pageview(url);
    }
    router.events.on("routeChangeComplete", handleRouteChange)
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    document.body.dir = locale === "ar" ? "rtl" : "ltr";
    setPageLoading(false);
  }, [i18n, locale]);

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 10 * (60 * 1000), // 10 mins
            cacheTime: 15 * (60 * 1000), // 15 mins
            refetchOnMount: true,
            refetchOnWindowFocus: true,
            refetchOnReconnect: true,
            retry: false,
          },
        },
      })
  );

  const getLayout = Component.getLayout || ((page) => page);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          {/* <DefaultSeo {...SEO(router.locale)} /> */}
         
          <CacheProvider value={locale === "ar" ? cacheRtl : cacheLtr}>
            <ThemeProvider theme={locale === "ar" ? themeAr : theme}>
              <AuthProvider>
                <CssBaseline />
                {pageLoading && (
                  <div
                    style={{
                      position: "fixed",
                      top: "0",
                      bottom: "0",
                      left: "0",
                      right: "0",
                      background: "#FFF",
                      zIndex: 999,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={require("../public/images/page-loading.gif")}
                      alt="loader"
                    />
                  </div>
                )}
                <NextNProgress color="#00bead" />
                <SnapchatPixel />
                <GoogleAnalytics trackPageViews />
                <PropertyContextProvider>
                  {getLayout(<Component {...pageProps} />)}
                  <ToastContainer position="bottom-right" />
                </PropertyContextProvider>
              </AuthProvider>
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </CacheProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}

export default appWithTranslation(MyApp);
