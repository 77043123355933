import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import {
  AcIcon,
  AreaIcon,
  BathRoomIcon,
  BedRoomIcon,
  BuildingAgeIcon,
  DiningIcon,
  ElectricityIcon,
  ElevatorIcon,
  FacingDirectionIcon,
  FurnishingIcon,
  GardenIcon,
  GymIcon,
  KitchenIcon,
  LoungeIcon,
  MaidIcon,
  MenGuestIcon,
  ParkingIcon,
  PoolIcon,
  StorageIcon,
  StreetViewIcon,
  TankIcon,
  WomenGuestIcon,
} from "../components/PropertyDetails/components/AdditionalDetails/icons";

const keyStr =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
const USER_LOCALSTORAGE_KEY = "sanadak_access_token";
export const SUPPORTED_FORMAT = "jpg,jpeg,gif,png";
export const MAX_IMAGE_SIZE = "5 Mb";
export const MAX_FILE_SIZE = "5";
export const IMG_URL = "https://dp57m2l5m3m9o.cloudfront.net/app/sanadak-social.webp";

const triplet = (e1, e2, e3) =>
  keyStr.charAt(e1 >> 2) +
  keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
  keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
  keyStr.charAt(e3 & 63);

export const rgbDataURL = (r, g, b) =>
  `data:image/gif;base64,R0lGODlhAQABAPAA${triplet(0, r, g) + triplet(b, 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;

export const BUY = 1;
export const SALE = 0;

export const getTranslatedField = (object, fieldName, locale = "en") => {
  let field = locale == "ar" ? fieldName + "Ar" : fieldName + "En";
  return object?.[field];
};

export const API_PATH = process.env.NEXT_PUBLIC_API_URL;

let swPath = process.env.NEXT_PUBLIC_WEB_URL;
swPath = swPath[swPath.length - 1] === "/" ? swPath.slice(0, -1) : swPath;
export const WEB_PATH = swPath;
export const GMAP_KEY =
  process.env.NEXT_PUBLIC_GMAP_KEY || "AIzaSyAnxxmLYz_H6tcHW2xW7XYwtcRqSMJLY38";
export const GMAP_ID = process.env.NEXT_PUBLIC_GMAP_ID || "8ce7c19168d37040";
export const STREAMCHAT_KEY =
  process.env.NEXT_PUBLIC_STREAMCHAT_KEY || "3q4ujemscv53";
// export const FCM_API_KEY =
//   process.env.NEXT_PUBLIC_FCM_API_KEY ||
//   "AIzaSyCyenhBTUzMcW13WoMDkUDDJhJtGjW0YxM";
// export const FCM_AUTH_DOMAIN =
//   process.env.NEXT_PUBLIC_FCM_AUTH_DOMAIN || "sanadaktest.firebaseapp.com";
// export const FCM_PROJECT_ID =
//   process.env.NEXT_PUBLIC_FCM_PROJECT_ID || "sanadaktest";
// export const FCM_STORAGE_BUCKET =
//   process.env.NEXT_PUBLIC_FCM_STORAGE_BUCKET || "sanadaktest.appspot.com";
// export const FCM_MESSAGING_SENDER_ID =
//   process.env.NEXT_PUBLIC_FCM_MESSAGING_SENDER_ID || "946840336430";
// export const FCM_APP_ID =
//   process.env.NEXT_PUBLIC_FCM_APP_ID ||
//   "1:946840336430:web:7427c3220bb018934787bf";
// export const FCM_VAPIDKEY =
//   process.env.NEXT_PUBLIC_FCM_VAPIDKEY ||
//   "BN15d6R7MPbmELXioAuZmCd1FXCwxA0PRqt2FlY-xvHAHWa0olTn-npFv4q27LWKSduxaEaU_iJpg3Wawkb2DWc";

export const FCM_API_KEY =
  process.env.NEXT_PUBLIC_FCM_API_KEY ||
  "AIzaSyAnxxmLYz_H6tcHW2xW7XYwtcRqSMJLY38";
export const FCM_AUTH_DOMAIN =
  process.env.NEXT_PUBLIC_FCM_AUTH_DOMAIN || "sanadak.firebaseapp.com";
export const FCM_PROJECT_ID =
  process.env.NEXT_PUBLIC_FCM_PROJECT_ID || "sanadak";
export const FCM_STORAGE_BUCKET =
  process.env.NEXT_PUBLIC_FCM_STORAGE_BUCKET || "sanadak.appspot.com";
export const FCM_MESSAGING_SENDER_ID =
  process.env.NEXT_PUBLIC_FCM_MESSAGING_SENDER_ID || "876565166704";
export const FCM_APP_ID =
  process.env.NEXT_PUBLIC_FCM_APP_ID ||
  "1:876565166704:web:63f839b9905d2742dad2b5";
export const FCM_VAPIDKEY =
  process.env.NEXT_PUBLIC_FCM_VAPIDKEY ||
  "BE9rpZG4B7tmeD1iFi-n4ONX-NN_pYUq543WiJcr8GE4yihJJ86gzz7lMqcdyGK5kX02-eKXr_66h393GxGtJvs";
export const NAFATH_AUTH_LINK =
  process?.env?.NEXT_PUBLIC_NAFATH_AUTHRIZATION ||
  "https://ed9d-176-224-37-179.ngrok.io/nafath/nafath/authorize";

export const PROP_ADD_EDIT_URL =
  process.env.NEXT_PUBLIC_PROP_ADD_EDIT_URL ||
  "https://dev.admin.sanadak.sa/agent";

export const EMP_URL = process.env.NEXT_PUBLIC_EMP_URL || "https://dev.admin.sanadak.sa/emp";

export const JOIN_NOW_URL =
  process.env.NEXT_PUBLIC_JOIN_NOW_URL ||
  "https://dev.admin.sanadak.sa/rent/now/";

export const fetcherApi = async (path, lang = "en") => {
  lang = lang == "ar" ? "ar-SA en-US" : "en-US";
  const url = API_PATH + "/" + path;
  try {
    const res = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Accept-Language": lang,
      },
    });
    return res;
  } catch (error) {
    return error.response;
  }
  // .then((res) => res.data)
};


export const fetcherApiPost = async (path, params) => {
  const url = API_PATH + "/" + path;
  try {
    const res = await axios.post(url, params, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (error) {
    return error.response;
  }
  // .then((res) => res.data)
};

export const fetcherApiGetWithoutLang = async (path, params) => {
  const url = API_PATH + "/" + path;
  try {
    const res = await axios.get(url, params, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.NEXT_PUBLIC_SECURITY_KEY,
      },
    });
    return res;
  } catch (error) {
    return error.response;
  }
  // .then((res) => res.data)
};

export const fetcherApiNew = async (path, lang = "en") => {
  lang = lang == "ar" ? "ar-SA" : "en-US";
  let token;
  if (typeof window !== 'undefined') {
    token = window.localStorage.getItem('access_token');
  }
  const url = API_PATH + "/" + path;
  try {
    const res = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Accept-Language": lang,
        authorization: `Bearer ${token}`,
      },
    });
    return res;
  } catch (error) {
    return error.response;
  }
  // .then((res) => res.data)
};
export const ClientId = "com.larkst.rila.apps.2389472023523-ivwbe5iweoc7mqwbn1";
export const Scop =
  "com.smartappholding.sanadak.identity com.smartappholding.sanadak.profiles com.smartappholding.sanadak.listings com.smartappholding.sanadak.content com.smartappholding.sanadak.search com.smartappholding.sanadak.reactions com.smartappholding.sanadak.follow com.smartappholding.sanadak.projects com.smartappholding.sanadak.areas com.smartappholding.sanadak.permissions com.smartappholding.sanadak.stories com.smartappholding.sanadak.analytics com.smartappholding.sanadak.chat com.smartappholding.sanadak.messages offline_access";

export const setUserToken = (token) => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(USER_LOCALSTORAGE_KEY, token);
  }
};

export const priceMinMax = [
  {
    label: "apartment",
    min: 15000,
    max: 400000,
    type: "rent",
    maxM: 400000,
    value: "10"
  },
  { label: "villa", min: 30000, max: 1000000, type: "rent", maxM: 1500000, value: "30" },
  { label: "building", min: 100000, max: 5000000, type: "rent", maxM: 5500000, value: "20" },
  { label: "land", min: 50000, max: 3000000, type: "rent", maxM: 3500000, value: "40" },
  // { label: "roof", min: 100000, max: 5000000, type: "rent", maxM: 5500000 },

  { label: "apartment", min: 3000, max: 3000000, type: "sale", maxM: 3500000, value: "10" },
  { label: "villa", min: 500000, max: 5000000, type: "sale", maxM: 5500000, value: "30" },
  {
    label: "building",
    min: 1000000,
    max: 15000000,
    type: "sale",
    maxM: 15000000,
    value: "20"
  },
  { label: "land", min: 100000, max: 10000000, type: "sale", maxM: 15000000, value: "40" },
  // { label: "roof", min: 100000, max: 5000000, type: "sale", maxM: 5500000 },
];
export const areaMinMax = [
  { label: "apartment", min: 50, max: 1000, maxM: 2000, value: "10" },
  { label: "villa", min: 150, max: 3000, maxM: 4000, value: "30" },
  { label: "building", min: 300, max: 3000, maxM: 4000, value: "20" },
  // { label: "roof", min: 300, max: 3000, maxM: 4000, value: "40" },
  { label: "land", min: 250, max: 10000, maxM: 11000, value: "40" },
];

export const priceMinMaxRequest = [
  { label: "Apartment", min: 15000, max: 400000, type: "Rent" },
  { label: "Villa", min: 30000, max: 1000000, type: "Rent" },
  { label: "Building", min: 100000, max: 5000000, type: "Rent" },
  { label: "Land", min: 50000, max: 3000000, type: "Rent" },
  { label: "Roof", min: 100000, max: 5000000, type: "Rent" },

  { label: "Apartment", min: 300000, max: 3000000, type: "Sale" },
  { label: "Villa", min: 500000, max: 5000000, type: "Sale" },
  { label: "Building", min: 1000000, max: 15000000, type: "Sale" },
  { label: "Land", min: 100000, max: 10000000, type: "Sale" },
  { label: "Roof", min: 100000, max: 5000000, type: "Sale" },
];

export const areaMinMaxRequest = [
  { label: "Apartment", min: 50, max: 1000 },
  { label: "Villa", min: 150, max: 3000 },
  { label: "Building", min: 300, max: 3000 },
  { label: "Roof", min: 300, max: 3000 },
  { label: "Land", min: 250, max: 10000 },
];

export const publicUrls = [
  "/property/[slug]",
  "property/[slug]",
  "agent/[slug]",
  "/agent/[slug]",
  "areas/[slug]",
  "/areas/[slug]",
  "project/[slug]",
  "project-model/[slug]",
  "/project-model/[slug]",
  "/project/[slug]",
  "/",
  "/en",
  "/ar",
  "en",
  "ar",
  "/login",
  "/map/search",
  "/advance-search",
  "/social-login",
  //"/verify/otp",
  "/notifications-listing",
  "/sign-up/account-type",
  "/sign-up/owner-type",
  "/sign-up/business",
  "/sign-up/owner",
  "/sign-up/buyer",
  "/sign-up/nafath-verification",
  "/privacy-policy",
  "/terms-of-use",
  "/terms-plain",
  "/privacy-plain",
  "/contact-us",
  "/about-us",
  "/financing",
  "/more-results",
  "[slug]",
  "/[slug]",
  "ksa/[...slug]",
  "/ksa/[...slug]",
  "details/[slug]",
  "/details/[slug]",
  "property-details/[slug]",
  "/property-details/[slug]",
  "/sitemap",
  "/my-request/request-edit/[slug]",
  "/my-request/request-view/[slug]",
  "/delete-account",
  "/en/delete-account",
];

export function useSearchDebounce(delay = 550) {
  const [search, setSearch] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);

  useEffect(() => {
    const delayFn = setTimeout(() => setSearch(searchQuery), delay);
    return () => clearTimeout(delayFn);
  }, [searchQuery, delay]);

  return [search, setSearchQuery];
}

export const IndoorFacilities = [
  {
    icon: "/images/icons/AreaIcon.svg",
    label: "area_m2",
    key: "lotSize",
    required: false,
    land: true,
  },

  {
    // icon: <AreaIcon />,
    icon: "/images/icons/BuildingAge.svg",
    label: "building_age",
    key: "buildingAge",
    required: false,
    land: false,
  },

  {
    // icon: <StreetViewIcon />,
    icon: "/images/icons/StreetView.svg",
    label: "street_width",
    key: "streetWidth",
    required: false,
    land: true,
  },

  {
    // icon: <FacingDirectionIcon />,
    icon: "/images/icons/FacingDirection.svg",
    label: "property_facing_direction",
    key: "propertyFacingDirection",
    required: false,
    land: true,
  },

  {
    // icon: <BedRoomIcon />,
    icon: "/images/icons/BedRoom.svg",
    label: "bedrooms",
    key: "numberBedrooms",
    required: true,
    land: false,
  },
  {
    // icon: <BathRoomIcon />,
    icon: "/images/icons/BathRoom.svg",
    label: "bathrooms",
    key: "numberBathrooms",
    required: true,
    land: false,
  },

  // {
  //   // icon: <LoungeIcon />,
  //   icon: "/images/icons/Lounge.svg",
  //   label: "lounge",
  //   key: "numberLounge",
  //   required: true,
  //   land: false,
  // },
  {
    // icon: <DiningIcon />,
    icon: "/images/icons/Dining.svg",
    label: "dining_room",
    key: "numberDining",
    required: false,
    land: false,
  },
  {
    // icon: <DiningIcon />,
    icon: "/images/icons/BedRoom.svg",
    label: "living_room",
    key: "numberLivingRooms",
    required: true,
    land: false,
  },
  {
    // icon: <MenGuestIcon />,
    icon: "/images/icons/MenGuest.svg",
    label: "men_guest_room",
    key: "numberMenGuestRooms",
    required: false,
    land: false,
  },
  {
    // icon: <WomenGuestIcon />,
    icon: "/images/icons/WomenGuest.svg",
    label: "women_guest_room",
    key: "numberWomenGuestRooms",
    required: false,
    land: false,
  },
  {
    // icon: <MaidIcon />,
    icon: "/images/icons/Maid.svg",
    label: "maid_room",
    key: "numberMaidRooms",
    required: false,
    land: false,
  },
  {
    // icon: <StorageIcon />,
    icon: "/images/icons/Storage.svg",
    label: "storage_room",
    key: "numberStorageRooms",
    required: false,

    // required: true,
  },
  {
    // icon: <StorageIcon />,
    icon: "/images/icons/WashRoom.svg",
    label: "washroom",
    key: "numberWashRooms",
    required: false,
    land: false,
  },
  //   // {
  //   //   icon: <WashRoomIcon/>,
  //   //   label: "wash_room",
  //   //   key: "isStorageRoomAvailable",
  //   // },
  {
    // icon: <FurnishingIcon />,
    icon: "/images/icons/Furnishing.svg",
    label: "furnished",
    key: "isFurnished",
    required: true,
    land: false,
  },
  {
    // icon: <TankIcon />,
    icon: "/images/icons/Tank.svg",
    label: "separate_tank",
    key: "isSeparateWaterTank",
    required: true,
    land: false,
  },
  {
    // icon: <ElectricityIcon />,
    icon: "/images/icons/Electricity.svg",
    label: "independent_electricity",
    key: "isIndependentElectricity",
    required: true,
    land: false,
  },
  // {
  //   //icon: <AcIcon />,
  //   icon: "/images/icons/Ac.svg",
  //   label: "air_condition",
  //   key: "numberACs",
  // },

  //   // {
  //   //   icon: <FounderIcon/>,
  //   //   label: "founder",
  //   //   key: "isIndependentElectricity",
  //   // },
  {
    icon: <AcIcon />,
    icon: "/images/icons/Ac.svg",
    label: "air_condition",
    key: "acTypeText",
    required: false,
    land: false,
  },
  {
    // icon: <KitchenIcon />,
    icon: "/images/icons/Kitchen.svg",
    label: "kitchen_installed",
    key: "kitchenStatusText",
    required: false,
    land: false,
  },
  {
    icon: "/images/icons/floor-plan.svg",
    label: "ground_floor_no",
    key: "floorNo",
    required: false,
    land: false,
  },
];

export const outdoorFacilities = [
  {
    // icon: <ParkingIcon />,
    icon: "/images/icons/Parking.svg",
    label: "private_parking",
    key: "numberParkingAreas",
  },
  {
    // icon: <PoolIcon />,
    icon: "/images/icons/Pool.svg",
    label: "swimming_pool",
    key: "isSwimmingPoolAvailable",
  },
  {
    // icon: <GardenIcon />,
    icon: "/images/icons/Garden.svg",
    label: "garden",
    key: "isGardenAvailable",
  },
  {
    // icon: <GymIcon />,
    icon: "/images/icons/Gym.svg",
    label: "gym",
    key: "isGymAvailable",
  },
  {
    // icon: <ElevatorIcon />,
    icon: "/images/icons/Elevator.svg",
    label: "elevator",
    key: "numberElevators",
  },
  {
    // icon: <ElevatorIcon />,
    icon: "/images/icons/Maid.svg",
    label: "driver_room",
    key: "isDriverRoomAvailable",
  },
];

export const reportOptions = [
  {
    label: "no_property_details",
    value: 10,
  },
  { label: "poor_quality", value: 20 },
  { label: "no_response_from_the_broker", value: 30 },
  { label: "inaccurate_information", value: 40 },
  { label: "other", value: 0 },
];

export const toastOptions = {
  position: "bottom-right",
  autoClose: true,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: 0,
  theme: "colored",
};

export const reportToastOptions = {
  position: "bottom-center",
  autoClose: true,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: 0,
  theme: "colored",
};

export function mapNumFormatter(num) {
  if (num > 999999) {
    return numberWithCommas((num / 1000000).toFixed(1)) + "M";
  } else if (num > 999 && num < 1000000) {
    // return (num / 1000).toFixed(1);
    return numberWithCommas(num);
  } else if (num < 900) {
    return num;
  }
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function priceFormate(price) {
  return price ? price.toLocaleString() : price;
}


// export function priceFormateProparity(price) {
//   const million = 1000000;
//   const thousand = 1000;

//   if (price >= million) {
//     return (price / million).toFixed(1) + "M";
//   } else if (price >= thousand) {
//     return (price / thousand).toFixed(0) + "k";
//   } else {
//     return price.toString();
//   }
// }
export function formatProperty(data, t) {
  let areaString = "";
  if (data?.numberBuildings) {
    areaString +=
      t("buildingsWithCount_other", { count: data?.numberBuildings }) + ", ";
  }
  if (data?.numberApartments) {
    areaString += t("apartWithCount_other", { count: data?.numberApartments }) + ", ";
  }

  if (data?.numberVillas) {
    areaString += t("villasWithCount_other", { count: data?.numberVillas }) + ", ";
  }

  if (data?.numberRoofs) {
    areaString += t("roofsWithCount_other", { count: data?.numberRoofs }) + ", ";
  }
  if (data?.numberLands) {
    areaString += t("landsWithCount_other", { count: data?.numberLands }) + ", ";
  }

  if (areaString?.length > 0) {
    areaString = areaString.slice(0, -2);
  }
  return areaString;
}

export function truncateString(str, n) {
  if (str.length > n) {
    return str.substring(0, n) + "...";
  } else {
    return str;
  }
}

// for use in Map marker position
export function getDocumentOffsetPosition(el) {
  // let top = 0,
  //   left = 0;
  // while (el !== null) {
  //   top += el.offsetTop;
  //   left += el.offsetLeft;
  //   el = el.offsetParent;
  // }
  // return { top, left };

  const rect = el.getBoundingClientRect();

  return {
    left: rect.left,
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
  };
}

export const momentArLocale = {
  months:
    "يناير_فبراير_مارس_أبريل_مايو_يونيو_يوليو_أغسطس_سبتمبر_أكتوبر_نوفمبر_ديسمبر".split(
      "_"
    ),
  monthsShort:
    "يناير_فبراير_مارس_أبريل_مايو_يونيو_يوليو_أغسطس_سبتمبر_أكتوبر_نوفمبر_ديسمبر".split(
      "_"
    ),
  weekdays: "الأحد_الإثنين_الثلاثاء_الأربعاء_الخميس_الجمعة_السبت".split("_"),
  weekdaysShort: "أحد_إثنين_ثلاثاء_أربعاء_خميس_جمعة_سبت".split("_"),
  weekdaysMin: "ح_ن_ث_ر_خ_ج_س".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  meridiemParse: /ص|م/,
  isPM: function (input) {
    return "م" === input;
  },
  meridiem: function (hour, minute, isLower) {
    if (hour < 12) {
      return "ص";
    } else {
      return "م";
    }
  },
  calendar: {
    sameDay: "[اليوم على الساعة] LT",
    nextDay: "[غدا على الساعة] LT",
    nextWeek: "dddd [على الساعة] LT",
    lastDay: "[أمس على الساعة] LT",
    lastWeek: "dddd [على الساعة] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "في %s",
    past: "منذ %s",
    s: "ثوان",
    ss: "%d ثانية",
    m: "دقيقة",
    mm: "%d دقائق",
    h: "ساعة",
    hh: "%d ساعات",
    d: "يوم",
    dd: "%d أيام",
    M: "شهر",
    MM: "%d أشهر",
    y: "سنة",
    yy: "%d سنوات",
  },
  week: {
    dow: 0, // Sunday is the first day of the week.
    doy: 6, // The week that contains Jan 1st is the first week of the year.
  },
};
export const filterType = [
  {
    label: "published",
    value: 0,
  },
  { label: "un_published", value: 1 },
  { label: "approved", value: 2 },
  { label: "pending_approval", value: 3 },
  { label: "draft", value: 4 },
  { label: "expired", value: 5 },
  { label: "sold", value: 6 },
  { label: "rented", value: 7 },
  { label: "rejected", value: 8 },
];
