import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  direction: "ltr",
  palette: {
    primary: {
      main: "#00bead",
      contrastText: "#fff",
    },
    grey: {
      main: '#757575', // Main grey color
      light: '#EEEEEE', // Light grey color
      dark: '#424242', // Dark grey color
    },
    secondary: {
      main: "#0055b2",
      contrastText: "#fff",
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main: "#000000",
    },
    brownGray: {
      main: "#909090",
    },
    midGray: {
      main: "#6e6e6e",
    },
    darkGray: {
      main: "#232529",
    },
    lightGray: {
      main: "#f0f0f0",
    },
    brownGrayTwo: {
      main: "#8e8e8e",
    },
    red: {
      main: "#e23636",
    },
    blue: {
      main: "#0055B2",
    },
    text: {
      main: "#000f2e",
    },
  },
  typography: {
    fontFamily: ["Avenir-Regular", "Avenir-Bold"].join(),
    htmlFontSize: 14,
    link: {
      fontFamily: "Avenir-Regular",
      fontSize: "1.142rem",
    },
    h1: {
      fontFamily: "Avenir-Regular",
      fontSize: "1.142rem",
    },
    h1Bold: {
      fontFamily: "Avenir-Bold",
      fontSize: "1.142rem",
    },
    h3: {
      fontFamily: "Avenir-Regular",
      fontSize: "1.142rem",
    },
    h3Bold: {
      fontFamily: "Avenir-Bold",
      fontSize: "1.142rem",
    },
    h4: {
      fontFamily: "Avenir-Regular",
      fontSize: "1.142rem",
    },
    h4Bold: {
      fontFamily: "Avenir-Bold",
      fontSize: "1.142rem",
    },
    h5: {
      fontFamily: "Avenir-Regular",
      fontSize: "1.142rem",
    },
    body1: {
      fontFamily: "Avenir-Regular",
      fontSize: "1.142rem",
      color: "#232529",
      lineHeight: 1,
    },
    body1En: {
      fontFamily: "Avenir-Regular",
      fontSize: "1.142rem",
      color: "#232529",
      lineHeight: 1,
    },
    body1Toggle: {
      fontFamily: "GE-SS-Medium",
      fontSize: "1.142rem",
      color: "#232529",
      lineHeight: 1,
    },
    body2: {
      fontFamily: "Avenir-Bold",
      fontSize: "1.142rem",
      color: "#232529",
      lineHeight: 1,
    },
    body2En: {
      fontFamily: "Avenir-Bold",
      lineHeight: 1,
      color: "#232529",
      fontSize: "1.142rem",
    },
    body3En: {
      fontFamily: "Avenir-Regular",
      lineHeight: 1,
      color: "#232529",
      fontSize: "1.142rem",
    },
    body3: {
      fontFamily: "Avenir-Regular",
      fontSize: "1.142rem",
      color: "#232529",
      lineHeight: 1,
    },
  },
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  shape: {
    pillRadius: "17px",
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained", size: "buttonBig" },
          style: {
            fontSize: "1.214rem",
          },
        },
        {
          props: { variant: "text", size: "bigger" },
          style: {
            fontSize: "1.286rem",
          },
        },
        {
          props: { variant: "text", size: "smaller" },
          style: {
            fontSize: "1rem",
          },
        },
      ],
    },
    MuiLink: {
      variants: [
        {
          props: { variant: "link", size: "default" },
          style: {
            fontSize: ".929rem",
            "@media (min-width:600px)": {
              fontSize: "1.143rem",
            },
          },
        },
        {
          props: { variant: "link", size: "extraSmall" },
          style: {
            fontSize: ".929rem",
          },
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: "p",
          body1En: "p",
          h1Bold: "h1",
          h4Bold: "h4",
          h3Bold: "h3",
        },
      },
      variants: [
        {
          props: { variant: "h1Bold", size: "huge" },
          style: {
            fontSize: "2.142rem",
            "@media (min-width:600px)": {
              fontSize: "2.714rem",
            },
          },
        },
        {
          props: { variant: "h1Bold", size: "biggest" },
          style: {
            fontSize: "1.429rem",
            "@media (min-width:600px)": {
              fontSize: "1.643rem",
            },
          },
        },
        {
          props: { variant: "h1Bold", size: "hugePlus" },
          style: {
            fontSize: "1.786rem",
            "@media (min-width:600px)": {
              fontSize: "2.143rem",
            },
            "@media (min-width:900px)": {
              fontSize: "3rem",
            },
            "@media (min-width:1200px)": {
              fontSize: "3.571rem",
            },
          },
        },
        {
          props: { variant: "h3", size: "bigger" },
          style: {
            fontSize: "1.285rem",
          },
        },
        {
          props: { variant: "h3Bold", size: "extraBig" },
          style: {
            fontSize: "1.143rem",
            "@media (min-width:600px)": {
              fontSize: "1.286rem",
            },
            "@media (min-width:900px)": {
              fontSize: "1.429rem",
            },
          },
        },
        {
          props: { variant: "h4", size: "bigger" },
          style: {
            fontSize: "1.285rem",
          },
        },
        {
          props: { variant: "h4", size: "extraBig" },
          style: {
            fontSize: "1.429rem",
          },
        },
        {
          props: { variant: "h4", size: "biggest" },
          style: {
            fontSize: "1.286rem",
            "@media (min-width:600px)": {
              fontSize: "1.643rem",
            },
          },
        },
        {
          props: { variant: "h4Bold", size: "extraBig" },
          style: {
            fontSize: "1.429rem",
          },
        },
        {
          props: { variant: "h4Bold", size: "big" },
          style: {
            fontSize: "1.214rem",
          },
        },
        {
          props: { variant: "body1", size: "extraSmall" },
          style: {
            fontSize: "0.928rem",
          },
        },
        {
          props: { variant: "body1", size: "smallest" },
          style: {
            fontSize: "0.857rem",
          },
        },
        {
          props: { variant: "body1", size: "tiny" },
          style: {
            fontSize: "0.714rem",
          },
        },
        {
          props: { variant: "body1En", size: "tiny" },
          style: {
            fontSize: "0.714rem",
          },
        },
        {
          props: { variant: "body1En", size: "smallest" },
          style: {
            fontSize: "0.857rem",
          },
        },
        {
          props: { variant: "body1En", size: "bigger" },
          style: {
            fontSize: "1.286rem",
          },
        },
        {
          props: { variant: "body1", size: "bigger" },
          style: {
            fontSize: "1rem",
            "@media (min-width:600px)": {
              fontSize: "1.286rem",
            },
          },
        },
        {
          props: { variant: "body1", size: "smaller" },
          style: {
            fontSize: "1rem",
          },
        },
        {
          props: { variant: "body1", size: "small" },
          style: {
            fontSize: ".929rem",
            "@media (min-width:600px)": {
              fontSize: "1.071rem",
            },
          },
        },
        {
          props: { variant: "body1", size: "extraBig" },
          style: {
            fontSize: "1.071rem",
            "@media (min-width:600px)": {
              fontSize: "1.143rem",
            },
            "@media (min-width:900px)": {
              fontSize: "1.429rem",
            },
          },
        },
        {
          props: { variant: "body2", size: "extraSmall" },
          style: {
            fontSize: "0.928rem",
          },
        },
        {
          props: { variant: "body2", size: "small" },
          style: {
            fontSize: "1.071rem",
          },
        },
        {
          props: { variant: "body2", size: "smaller" },
          style: {
            fontSize: "1rem",
          },
        },
        {
          props: { variant: "body2En", size: "extraSmall" },
          style: {
            fontSize: "0.928rem",
          },
        },
        {
          props: { variant: "body3En", size: "smallest" },
          style: {
            fontSize: "0.875rem",
          },
        },
        {
          props: { variant: "body2En", size: "smaller" },
          style: {
            fontSize: "1rem",
          },
        },
        {
          props: { variant: "body2En", size: "big" },
          style: {
            fontSize: "1.214rem",
          },
        },
        {
          props: { variant: "body2", size: "smallest" },
          style: {
            fontSize: "0.857rem",
          },
        },
        {
          props: { variant: "body2", size: "tiny" },
          style: {
            fontSize: "0.714rem",
          },
        },
        {
          props: { variant: "body2", size: "big" },
          style: {
            fontSize: "1rem",
            "@media (min-width:600px)": {
              fontSize: "1.214rem",
            },
          },
        },
        {
          props: { variant: "body2", size: "biggest" },
          style: {
            fontSize: "1.643rem",
          },
        },
        {
          props: { variant: "body2", size: "bigger" },
          style: {
            fontSize: "1.286rem",
          },
        },
        {
          props: { variant: "body2", size: "extraBig" },
          style: {
            fontSize: "1.429rem",
          },
        },
        {
          props: { variant: "body3", size: "extraSmall" },
          style: {
            fontSize: "0.928rem",
          },
        },
        {
          props: { variant: "body3", size: "smaller" },
          style: {
            fontSize: "1rem",
          },
        },
        {
          props: { variant: "body3", size: "smallest" },
          style: {
            fontSize: "0.857rem",
          },
        },
      ],
    },
  },
});

const themeAr = createTheme({
  direction: "rtl",
  palette: {
    primary: {
      main: "#00bead",
      contrastText: "#fff",
    },
    secondary: {
      main: "#0055b2",
      contrastText: "#fff",
    },
    white: {
      main: "#ffffff",
    },
    black: {
      main: "#000000",
    },
    brownGray: {
      main: "#909090",
    },
    midGray: {
      main: "#6e6e6e",
    },
    darkGray: {
      main: "#232529",
    },
    lightGray: {
      main: "#f0f0f0",
    },
    brownGrayTwo: {
      main: "#8e8e8e",
    },
    red: {
      main: "#e23636",
    },
    blue: {
      main: "#0055B2",
    },
    text: {
      main: "#000f2e",
    },
  },
  typography: {
    fontFamily: ["GE-SS-Medium", "GE-SS-Bold", "GE-SS-Light"].join(),
    htmlFontSize: 14,
    link: {
      fontFamily: "GE-SS-Medium",
      fontSize: "1.14rem",
    },
    h1: {
      fontFamily: "GE-SS-Medium",
      fontSize: "1.142rem",
    },
    h1Bold: {
      fontFamily: "GE-SS-Bold",
      fontSize: "1.142rem",
    },
    h3: {
      fontFamily: "GE-SS-Medium",
      fontSize: "1.142rem",
    },
    h3Bold: {
      fontFamily: "GE-SS-Bold",
      fontSize: "1.142rem",
    },
    h4: {
      fontFamily: "GE-SS-Medium",
      fontSize: "1.142rem",
    },
    h4Bold: {
      fontFamily: "GE-SS-Bold",
      fontSize: "1.142rem",
    },
    h5: {
      fontFamily: "GE-SS-Medium",
      fontSize: "1.142rem",
    },
    body1: {
      fontFamily: "GE-SS-Medium",
      lineHeight: 1,
      color: "#232529",
      fontSize: "1.142rem",
    },
    body1En: {
      fontFamily: "Avenir-Regular",
      fontSize: "1.142rem",
      color: "#232529",
      lineHeight: 1,
    },
    body1Toggle: {
      fontFamily: "Avenir-Regular",
      fontSize: "1.142rem",
      color: "#232529",
      lineHeight: 1,
    },
    body2: {
      fontFamily: "GE-SS-Bold",
      lineHeight: 1,
      color: "#232529",
      fontSize: "1.142rem",
    },
    body2En: {
      fontFamily: "Avenir-Bold",
      lineHeight: 1,
      color: "#232529",
      fontSize: "1.142rem",
    },
    body3En: {
      fontFamily: "Avenir-Regular",
      lineHeight: 1,
      color: "#232529",
      fontSize: "1.142rem",
    },
    body3: {
      fontFamily: "GE-SS-light",
      lineHeight: 1,
      color: "#232529",
      fontSize: "1.142rem",
    },
  },
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  shape: {
    pillRadius: "17px",
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "text", size: "bigger" },
          style: {
            fontSize: "1.286rem",
          },
        },
        {
          props: { variant: "text", size: "big" },
          style: {
            fontSize: "1.214rem",
          },
        },
        {
          props: { variant: "text", size: "smaller" },
          style: {
            fontSize: "1rem",
          },
        },
      ],
    },
    MuiLink: {
      variants: [
        {
          props: { variant: "link", size: "default" },
          style: {
            fontSize: ".929rem",
            "@media (min-width:600px)": {
              fontSize: "1.143rem",
            },
          },
        },
        {
          props: { variant: "link", size: "extraSmall" },
          style: {
            fontSize: ".929rem",
          },
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: "p",
          body1En: "p",
          h1Bold: "h1",
          h4Bold: "h4",
          h3Bold: "h3",
        },
      },
      variants: [
        {
          props: { variant: "h1Bold", size: "huge" },
          style: {
            fontSize: "2.142rem",
            "@media (min-width:600px)": {
              fontSize: "2.714rem",
            },
          },
        },
        {
          props: { variant: "h1Bold", size: "biggest" },
          style: {
            fontSize: "1.429rem",
            "@media (min-width:600px)": {
              fontSize: "1.643rem",
            },
          },
        },
        {
          props: { variant: "h1Bold", size: "hugePlus" },
          style: {
            fontSize: "1.786rem",
            "@media (min-width:600px)": {
              fontSize: "2.143rem",
            },
            "@media (min-width:900px)": {
              fontSize: "3rem",
            },
            "@media (min-width:1200px)": {
              fontSize: "3.571rem",
            },
          },
        },
        {
          props: { variant: "h3", size: "bigger" },
          style: {
            fontSize: "1.285rem",
          },
        },
        {
          props: { variant: "h3Bold", size: "extraBig" },
          style: {
            fontSize: "1.286rem",
            "@media (min-width:600px)": {
              fontSize: "1.429rem",
            },
          },
        },
        {
          props: { variant: "h4", size: "bigger" },
          style: {
            fontSize: "1.285rem",
          },
        },
        {
          props: { variant: "h4", size: "extraBig" },
          style: {
            fontSize: "1.429rem",
          },
        },
        {
          props: { variant: "h4", size: "biggest" },
          style: {
            fontSize: "1.286rem",
            "@media (min-width:600px)": {
              fontSize: "1.643rem",
            },
          },
        },
        {
          props: { variant: "h4Bold", size: "extraBig" },
          style: {
            fontSize: "1.429rem",
          },
        },
        {
          props: { variant: "h4Bold", size: "big" },
          style: {
            fontSize: "1.214rem",
          },
        },
        {
          props: { variant: "body1", size: "extraSmall" },
          style: {
            fontSize: "0.928rem",
          },
        },
        {
          props: { variant: "body1", size: "tiny" },
          style: {
            fontSize: "0.714rem",
          },
        },
        {
          props: { variant: "body1En", size: "tiny" },
          style: {
            fontSize: "0.714rem",
          },
        },
        {
          props: { variant: "body1En", size: "smallest" },
          style: {
            fontSize: "0.857rem",
          },
        },
        {
          props: { variant: "body1En", size: "bigger" },
          style: {
            fontSize: "1.286rem",
          },
        },
        {
          props: { variant: "body1", size: "small" },
          style: {
            fontSize: "1.071rem",
          },
        },
        {
          props: { variant: "body1", size: "bigger" },
          style: {
            fontSize: "1rem",
            "@media (min-width:600px)": {
              fontSize: "1.286rem",
            },
          },
        },
        {
          props: { variant: "body1", size: "smaller" },
          style: {
            fontSize: "1rem",
          },
        },
        {
          props: { variant: "body1", size: "smallest" },
          style: {
            fontSize: "0.857rem",
          },
        },
        {
          props: { variant: "body1", size: "extraBig" },
          style: {
            fontSize: "1.071rem",
            "@media (min-width:600px)": {
              fontSize: "1.143rem",
            },
            "@media (min-width:900px)": {
              fontSize: "1.429rem",
            },
          },
        },
        {
          props: { variant: "body2", size: "extraSmall" },
          style: {
            fontSize: "0.928rem",
          },
        },
        {
          props: { variant: "body2", size: "small" },
          style: {
            fontSize: "1.071rem",
          },
        },
        {
          props: { variant: "body2", size: "smaller" },
          style: {
            fontSize: "1rem",
          },
        },
        {
          props: { variant: "body2En", size: "extraSmall" },
          style: {
            fontSize: "0.928rem",
          },
        },
        {
          props: { variant: "body3En", size: "smallest" },
          style: {
            fontSize: "0.875rem",
          },
        },
        {
          props: { variant: "body2En", size: "smaller" },
          style: {
            fontSize: "1rem",
          },
        },
        {
          props: { variant: "body2En", size: "big" },
          style: {
            fontSize: "1.214rem",
          },
        },
        {
          props: { variant: "body2", size: "smallest" },
          style: {
            fontSize: "0.857rem",
          },
        },

        {
          props: { variant: "body2", size: "big" },
          style: {
            fontSize: "1rem",
            "@media (min-width:600px)": {
              fontSize: "1.214rem",
            },
          },
        },
        {
          props: { variant: "body2", size: "tiny" },
          style: {
            fontSize: "0.714rem",
          },
        },
        {
          props: { variant: "body2", size: "biggest" },
          style: {
            fontSize: "1.643rem",
          },
        },
        {
          props: { variant: "body2", size: "bigger" },
          style: {
            fontSize: "1.286rem",
          },
        },
        {
          props: { variant: "body2", size: "extraBig" },
          style: {
            fontSize: "1.429rem",
          },
        },
        {
          props: { variant: "body3", size: "smaller" },
          style: {
            fontSize: "1rem",
          },
        },
        {
          props: { variant: "body3", size: "small" },
          style: {
            fontSize: "1.071rem",
          },
        },
        {
          props: { variant: "body3", size: "smallest" },
          style: {
            fontSize: "0.857rem",
          },
        },
      ],
    },
  },
});

export default theme;
export { themeAr };
