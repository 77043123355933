/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */

export default {
  title: "Sanadak",
  titleTemplate: " %s | Sanadak",
  defaultTitle: "Sanadak",
  description: "Sanadak",
  openGraph: {
    type: "website",
    locale: "en_IE",
    url: "https://www.url.ie/",
    site_name: "Sanadak",
  },
  twitter: {
    handle: "@handle",
    site: "@site",
    cardType: "summary_large_image",
  },
};


module.exports = (language) => {
  return {
    title : language === 'en' ? 'Sanadak Platform' : 'منصة سندك العقارية',
    description : language === 'en' ? 'Platform for listing properties to sell and rent in Saudi Arabia' :'منصة عقارية لعرض العقارات للبيع او الايجار في السعودية',
    openGraph: {
      type: "website",
      locale: "en_IE",
      url: "https://www.url.ie/",
      site_name: "Sanadak",
    },
    twitter: {
      handle: "@handle",
      site: "@site",
      cardType: "summary_large_image",
    },
  };
};